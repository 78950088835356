import moment from 'moment';
import PerformanceScore from './PerformanceScore';
import classificationConfig from '../config/performance_score_class_ranges.json';

function average(array) {
    const filteredArray = array.filter(value => !isNaN(value) && value !== null);
    if (filteredArray.length === 0) return NaN; // Return NaN if there are no valid numbers to average
    return parseFloat((filteredArray.reduce((a, b) => a + b, 0) / filteredArray.length).toFixed(2));
  }

export default class PerformanceScores {
  constructor({ performance_scores }) {
    this.performance_scores = performance_scores
  }

  static createFromAPI(performance_score_api) {
    const scores = performance_score_api
      .map((apiData) => PerformanceScore.createFromAPI(apiData))
      .sort((a, b) => new Date(a.date) - new Date(b.date));
    return new PerformanceScores({ performance_scores: scores });
  }

  static addScoreByDate(scores_by_date, performance_score) {
    let date = performance_score.date.split(" ")[0];
    let formattedDate = moment(date).format('MMMM DD, YYYY');

    if (!scores_by_date[date]) {
        scores_by_date[date] = {
            dates: [],
            scores: [],
        }
    }
    scores_by_date[date].dates.push(date);
    scores_by_date[date].scores.push(performance_score.raw_score)
  }

  static extractScoresFromList(performance_scores) {
    // Create object to store scores grouped by date
    let performanceScoresByDate = {};
    
    // Group scores by date and convert to daily averages
    for (const performanceScore of performance_scores) {
      PerformanceScores.addScoreByDate(performanceScoresByDate, performanceScore);
    }
  
    // Create arrays for dates and corresponding daily averages
    const dates = [];
    const dailyAverages = [];
    
    // Convert the object to sorted arrays (by date)
    Object.entries(performanceScoresByDate)
      .sort(([dateA], [dateB]) => moment(dateA) - moment(dateB))
      .forEach(([date, data]) => {
        dates.push(date);
        // Calculate the average for the day
        dailyAverages.push(average(data.scores));
      });
    
    // Calculate 3-day rolling averages
    const rollingAverages = [];
    
    // For each date, calculate its 3-day average
    for (let i = 0; i < dates.length; i++) {
      const currentDate = moment(dates[i]);
      
      // If the current day's average is NaN or null, don't calculate rolling average
      if (isNaN(dailyAverages[i]) || dailyAverages[i] === null) {
        rollingAverages.push(NaN);
        continue;
      }
      
      // Array to store values for 3-day average calculation
      const daysToConsider = [dailyAverages[i]];
      
      // Look back at previous dates within the 3-day window
      for (let j = 0; j < i; j++) {
        const previousDate = moment(dates[j]);
        const daysDifference = currentDate.diff(previousDate, 'days');
        
        // Only include if it's within the last 2 days
        if (daysDifference > 0 && daysDifference <= 2) {
          if (!isNaN(dailyAverages[j]) && dailyAverages[j] !== null) {
            daysToConsider.push(dailyAverages[j]);
          }
        }
      }
      
      // Calculate the rolling average
      const rollingAvg = parseFloat(
        (daysToConsider.reduce((a, b) => a + b, 0) / daysToConsider.length).toFixed(2)
      );
      
      rollingAverages.push(rollingAvg);
    }
    
    // Return the results object with dates and 3-day rolling averages
    return {
      dates: dates,
      scores: rollingAverages
    };
  }

  getClassificationFromScore(score) {
    // Loop over the config entries (assuming they're sorted descending by "min")
    for (const entry of classificationConfig) {
      if (score >= entry.min && score < entry.max) {
        // Return the whole entry, which includes both label and color.
        return { label: entry.label, color: entry.color };
      }
    }
    // If no match is found, you could return a default or throw an error.
    return { label: 'Unknown', color: '#000000' };
  }

  get performance_scores() {
    return this._performance_scores;
  }

  set performance_scores(performance_scores) {
    if (!Array.isArray(performance_scores)) {
      throw new Error(
        'PerformanceScores expects an array of PerformanceScore objects.'
      );
    }
    for (const score of performance_scores) {
      if (!(score instanceof PerformanceScore)) {
        throw new Error(
          'PerformanceScores expects an array of PerformanceScore objects.'
        );
      }
    }
    this._performance_scores = performance_scores;
  }
}
