export default class IndividualNormalRange {
  constructor({ mean, std }) {
    this.mean = mean;
    this.std = std;
    this.valid = mean && std ? true : false;
    this.range = this.calculateMetricRange();
  }

  calculateMetricRange(k = 1.96, k_extreme = 6) {
    if (this.valid) {
      const low = Math.round(this.mean - k * this.std, 2);
      const high = Math.round(this.mean + k * this.std, 2);
      const extreme_low = Math.round(this.mean - k_extreme * this.std, 2);
      const extreme_high = Math.round(this.mean + k_extreme * this.std, 2);
      return { extreme_low, low, high, extreme_high };
    }
    return null, null, null, null;
  }

  get mean() {
    return this._mean;
  }
  set mean(mean) {
    this._mean = mean;
  }

  get std() {
    return this._std;
  }
  set std(std) {
    this._std = std;
  }

  get range() {
    return this._range;
  }
  set range(range) {
    this._range = range;
  }
}
