import Navbar from "../components/NavBars/Navbar";
import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router";
import SideNavbar from "../components/NavBars/SideNavbar";
import Footer from "../components/Footer";
import PatientHeader from "../components/Headers/PatientHeader";
import PatientNavbar from "../components/NavBars/PatientNavbar";
import Chart from "chart.js/auto";
import "chartjs-adapter-date-fns";
import Global from "../utils/Global";
import dataLabelPlugin from "chartjs-plugin-datalabels";
import { SubjectDemographicsTable } from "../components/Tables/SubjectDemographicsTable";
import { RemoteCollectionTable } from "../components/Tables/RemoteCollectionTable";
import StanceTimeBarGraph from "../components/Graphs/StanceTimeBarGraph";
import StrideTimeGraph from "../components/Graphs/StrideTimeGraph";
import StanceTimeLineGraph from "../components/Graphs/StanceTimeLineGraph";
import SwingASIGraph from "../components/Graphs/SwingASIGraph";
import StanceASIGraph from "../components/Graphs/StanceASIGraph";
import PainPRO from "../components/Graphs/PainPRO";
import Trial from "../utils/Trial";
import display_types from "../config/display_types";
import { DateRangePicker } from "react-dates";
import PerformanceScoreGraph from "../components/Graphs/PerformanceScoreGraph";
import PerformanceScores from "../utils/PerformanceScores";

Chart.register(dataLabelPlugin);
const dayjs = require('dayjs');

export default function GaitMetrics() {
  const [isLoading, setIsLoading] = useState(true);
  const [subject, setSubject] = useState(null);
  const [norm, setNorm] = useState(null);
  const [metrics, setMetrics] = useState(null);
  const [performanceScores, setPerformanceScores] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [trialList, setTrialList] = useState(null);
  const display_type = Global.localAppHandler.user.display_type;

  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const subject = await Global.localAppHandler.app_get_subject();
        const trialList = await Global.localAppHandler.app_get_trial_list({ include_metrics: true });
        const extracted_metrics = Trial.extractMetricsFromList(trialList);
        const performanceScores = await Global.localAppHandler.app_retrieve_performance_scores();
        const extracted_performance_scores = PerformanceScores.extractScoresFromList(performanceScores.performance_scores)
        const norm = await Global.localAppHandler.app_retrieve_normal_ranges()
        // const periodInformation = await Global.localAppHandler.app_retrieve_period_information({ include_current_period: true, include_all_periods: true });
        setNorm(norm);
        setSubject(subject);
        setMetrics(extracted_metrics);
        setPerformanceScores(extracted_performance_scores)
        setTrialList(trialList);
      } catch (error) {
        navigate("/dashboard");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  useEffect(() => {
    document.body.classList.add("nav-fixed");

    return () => {
      document.body.classList.remove("nav-fixed");
    };
  }, []);

  const [activeTab, setActiveTab] = useState("alltime");

  const handleTabClick = (tabIndex) => {
    setStartDate(null);
    setEndDate(null);
    setActiveTab(tabIndex);
  };

  const [minDate, maxDate, paddedMinDateStr, paddedMaxDateStr] = useMemo(() => {
    let dates;
    if (!metrics) {
      dates = [];
    } else {
      dates = metrics.dates
      .map((label) => new Date(`${label}T00:00:00-05:00`)) // Append EST offset
      .filter((date) => !isNaN(date.getTime()));
    }
    let min, max;
    if (activeTab === "alltime") {
      if (dates.length > 0) {
        min = new Date(Math.min(...dates.map((date) => date.getTime())));
        max = new Date(Math.max(...dates.map((date) => date.getTime())));
      } else {
        // Set to default values if no valid dates are found
        min = new Date();
        max = new Date();
      }
    } else if (activeTab === "7day") {
      if (dates.length > 0) {
        max = new Date();
        min = new Date(max);
        min.setDate(min.getDate() - 6); // 6 because end date is included
      } else {
        // Set to default values if no valid dates are found
        min = new Date();
        max = new Date();
      }
    } else if (activeTab === "30day") {
      if (dates.length > 0) {
        max = new Date();
        min = new Date(max);
        min.setDate(min.getDate() - 29); // 29 because end date is included
      } else {
        // Set to default values if no valid dates are found
        min = new Date();
        max = new Date();
      }
    } else if (activeTab === "90day") {
      if (dates.length > 0) {
        max = new Date();
        min = new Date(max);
        min.setDate(min.getDate() - 89); // 89 because end date is included
      } else {
        // Set to default values if no valid dates are found
        min = new Date();
        max = new Date();
      }
    } else if (activeTab === "select") {
      const isStartDateValid = startDate && !isNaN(new Date(startDate).getTime());
      const isEndDateValid = endDate && !isNaN(new Date(endDate).getTime());

      if (
        isStartDateValid &&
        isEndDateValid &&
        new Date(startDate) <= new Date(endDate) &&
        new Date(endDate) - new Date(startDate) <= 10 * 365 * 24 * 60 * 60 * 1000 // Max 10 years
      ) {
        min = new Date(startDate);
        max = new Date(endDate);
      } else {
        min = new Date();
        max = new Date();
      }
    }
    const range = max.getTime() - min.getTime();
    let padding = (0.2 * range) / (1000 * 60 * 60 * 24); // converting from ms to days

    if (padding < 2) {
      padding = 2;
    }
    let minDatePadded = new Date(min); // Clone the Date object
    minDatePadded.setDate(minDatePadded.getDate() - padding);

    let maxDatePadded = new Date(max); // Clone the Date object
    maxDatePadded.setDate(maxDatePadded.getDate() + padding);

    return [min, max, minDatePadded.toISOString(), maxDatePadded.toISOString()];
  }, [metrics, activeTab, startDate, endDate]);

  return (
    <>
      <Navbar />
      <div id="layoutSidenav">
        <SideNavbar
          patientSubHeaderEnabled={true}
          activeTab={{ studyOverview: false, subjects: true, manageTeam: false, reviewReports: false, remoteAlerts: false }}
        />
        <div id="layoutSidenav_content">
          <main>
            <PatientHeader
              bg={"bg-white"}
              subjectName={
                display_type === display_types.PHI
                  ? subject?.first_name && subject?.last_name
                    ? `${subject.first_name} ${subject.last_name}`
                    : ""
                  : subject?.name
              }
            />
            {/* Main page content*/}
            <div className="container-xl px-4 mt-n10">
              <PatientNavbar activeTab={{ overview: false, trials: false, detailed: false, gait_metrics: true, reports: false }} />
              <div className="card mb-4">
                <div className="card-body">
                  <SubjectDemographicsTable subject={subject} displayType={display_type} />
                </div>
              </div>

              <div className="overviewChartBox">
                <div className="row mb-4 justify-content-center align-items-center">
                  {/* Tabs Column (stretches, centered content) */}
                  <div className="col-auto">
                    <div className="btn-group" role="group" aria-label="Basic example">
                      <div className={`btn btn-primary ${activeTab === "alltime" ? "active" : ""}`} onClick={() => handleTabClick("alltime")}>
                        All Time
                      </div>
                      <div className={`btn btn-primary ${activeTab === "7day" ? "active" : ""}`} onClick={() => handleTabClick("7day")}>
                        7 Day
                      </div>
                      <div className={`btn btn-primary ${activeTab === "30day" ? "active" : ""}`} onClick={() => handleTabClick("30day")}>
                        30 Day
                      </div>
                      <div className={`btn btn-primary ${activeTab === "90day" ? "active" : ""}`} onClick={() => handleTabClick("90day")}>
                        90 Day
                      </div>
                      <div className={`ml-2 btn btn-blue ${activeTab === "select" ? "active" : ""}`} onClick={() => handleTabClick("select")}>
                        Select Dates
                      </div>
                    </div>
                  </div>

                  {/* Date Selector Coumn (fixed width) */}
                  <div className="col-auto" style={{ fontSize: "0.8em" }}>
                    <DateRangePicker
                      startDate={startDate}
                      startDateId="start_date_id"
                      disabled={activeTab !== "select"}
                      endDate={endDate}
                      endDateId="end_date_id"
                      onDatesChange={({ startDate, endDate }) => {
                        setStartDate(startDate);
                        setEndDate(endDate);
                      }}
                      focusedInput={focusedInput}
                      onFocusChange={(focusedInput) => {
                        setFocusedInput(focusedInput);
                      }}
                      numberOfMonths={1} // Show 1 month at a time
                      isOutsideRange={() => false} // Allow past/future date selection
                      displayFormat="MMM D, YYYY" // How dates are displayed (moment format)
                    />
                  </div>
                </div>

                {
                  norm && norm.all_valid ?
                  <div className="row">
                  <div className="col-lg-12">
                    <PerformanceScoreGraph
                      labels={performanceScores ? performanceScores.dates : []}
                      data={performanceScores ? performanceScores.scores : []}
                      trialNames={metrics ? metrics.trial_names : []}
                      lastUpdatedTime={""}
                      timeAxis={"day"}
                      minDate={minDate}
                      maxDate={maxDate}
                      paddedMinDateStr={paddedMinDateStr}
                      paddedMaxDateStr={paddedMaxDateStr}
                    />
                  </div>
                </div> :
                <></>
                }


                <div className="row">
                  <div className="col-lg-6">
                    <StanceASIGraph
                      labels={metrics ? metrics.dates : []}
                      data={metrics ? metrics.stance_asis : []}
                      trialNames={metrics ? metrics.trial_names : []}
                      lastUpdatedTime={""}
                      timeAxis={"day"}
                      minDate={minDate}
                      maxDate={maxDate}
                      paddedMinDateStr={paddedMinDateStr}
                      paddedMaxDateStr={paddedMaxDateStr}
                      norm={norm}
                    />
                  </div>
                  <div className="col-lg-6">
                    <RemoteCollectionTable trialList={trialList ? trialList : []} minDate={minDate} maxDate={maxDate} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <SwingASIGraph
                      labels={metrics ? metrics.dates : []}
                      data={metrics ? metrics.swing_asis : []}
                      trialNames={metrics ? metrics.trial_names : []}
                      lastUpdatedTime={""}
                      timeAxis={"day"}
                      minDate={minDate}
                      maxDate={maxDate}
                      paddedMinDateStr={paddedMinDateStr}
                      paddedMaxDateStr={paddedMaxDateStr}
                      norm={norm}
                    />
                  </div>
                  <div className="col-lg-6">
                    <PainPRO
                      labels={metrics ? metrics.dates : []}
                      data={metrics ? metrics.vas_scores : []}
                      trialNames={metrics ? metrics.trial_names : []}
                      timeAxis={"day"}
                      minDate={minDate}
                      maxDate={maxDate}
                      paddedMinDateStr={paddedMinDateStr}
                      paddedMaxDateStr={paddedMaxDateStr}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <StanceTimeLineGraph
                      labels={metrics ? metrics.dates : []}
                      data_l={metrics ? metrics.stance_times_left : []}
                      data_r={metrics ? metrics.stance_times_right : []}
                      trialNames={metrics ? metrics.trial_names : []}
                      lastUpdatedTime={""}
                      timeAxis={"day"}
                      minDate={minDate}
                      maxDate={maxDate}
                      paddedMinDateStr={paddedMinDateStr}
                      paddedMaxDateStr={paddedMaxDateStr}
                      norm={norm}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <StrideTimeGraph
                      labels={metrics ? metrics.dates : []}
                      data={metrics ? metrics.stride_times : []}
                      trialNames={metrics ? metrics.trial_names : []}
                      lastUpdatedTime={""}
                      timeAxis={"day"}
                      minDate={minDate}
                      maxDate={maxDate}
                      paddedMinDateStr={paddedMinDateStr}
                      paddedMaxDateStr={paddedMaxDateStr}
                      norm={norm}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="card mb-4">
              <div className="d-flex card-header align-items-center justify-content-between">FAOS Scores</div>
                <div className="card-body">
                  <PROTable
                    values={[
                      { faos: 98, symptoms: 100, pain: 96, daily: 56, sports: 100, qol: 88, date: "11/23/2023" },
                      { faos: 98, symptoms: 100, pain: 96, daily: 56, sports: 100, qol: 88, date: "11/23/2023" },
                      { faos: 98, symptoms: 100, pain: 96, daily: 56, sports: 100, qol: 88, date: "11/23/2023" },
                    ]}
                  />
                </div>
              </div> */}
            </div>
          </main>

          <Footer light={true} />
        </div>
      </div>
    </>
  );
}
