import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import Global from "../../utils/Global";
import { useNavigate } from "react-router-dom";
import patient_account_types from "../../config/patient_account_types";

export function RemoteCollectionTable({ trialList, minDate, maxDate, ...props }) {
  const navigate = useNavigate();
  const active_subject = Global.localAppHandler.active_subject;

  // Define the `onRowClick` function
  const onRowClick = (trial) => {
    const newPath = window.location.pathname.slice(0, window.location.pathname.lastIndexOf("/")) + "/trials/" + trial.trial_id;

    Global.localAppHandler
      .app_set_active_trial(trial.trial_id)
      .then(() => {
        navigate(newPath);
        window.scrollTo(0, 0); // Reset scroll to top after navigation
      })
      .catch(() => {
        console.log("Error setting active trial");
      });
  };

  // Filter trials based on trial_date range
  const filteredData = useMemo(() => {
    if (!trialList || !minDate || !maxDate) return trialList;

    const minDateObj = new Date(minDate);
    const maxDateObj = new Date(maxDate);

    return trialList.filter((trial) => {
      const trialDate = trial.trial_date ? new Date(trial.trial_date) : null;

      if (trialDate) {
        // Normalize trialDate, minDateObj, and maxDateObj to midnight (00:00:00)
        const normalizedTrialDate = new Date(trialDate.getFullYear(), trialDate.getMonth(), trialDate.getDate());
        const normalizedMinDate = new Date(minDateObj.getFullYear(), minDateObj.getMonth(), minDateObj.getDate());
        const normalizedMaxDate = new Date(maxDateObj.getFullYear(), maxDateObj.getMonth(), maxDateObj.getDate());

        // Compare only the normalized dates
        return normalizedTrialDate >= normalizedMinDate && normalizedTrialDate <= normalizedMaxDate;
      }

      return false; // Exclude trials with invalid dates
    });
  }, [trialList, minDate, maxDate]);

  // Calculate the fraction
  const fraction = useMemo(() => {
    if (!minDate || !maxDate || filteredData.length === 0) return "N/A";

    const minDateObj = new Date(minDate);
    const maxDateObj = new Date(maxDate);

    // Calculate the number of days between the dates
    const diffInDays = (maxDateObj.getTime() - minDateObj.getTime()) / (1000 * 60 * 60 * 24) + 1;

    // Extract unique dates from filteredData
    const uniqueDays = new Set(
      filteredData.map((trial) => {
        const trialDate = new Date(trial.trial_date);
        return trialDate.toISOString().split("T")[0]; // Keep only the YYYY-MM-DD part
      })
    );

    // Return the fraction
    return `${uniqueDays.size} / ${Math.round(diffInDays)}`;
  }, [filteredData, minDate, maxDate]);

  // Define columns for react-table
  const columns = useMemo(
    () => [
      {
        Header: "Trial Date",
        accessor: "trial_date",
        sortType: (rowA, rowB) => {
          const dateA = rowA.original.trial_date ? new Date(rowA.original.trial_date) : null;
          const dateB = rowB.original.trial_date ? new Date(rowB.original.trial_date) : null;
          if (!dateA) return 1; // Push empty dates to the bottom
          if (!dateB) return -1; // Push empty dates to the bottom
          return dateA - dateB; // Compare valid dates
        },
        Cell: ({ value }) => (value ? new Date(value).toLocaleString() : "n/a"), // Format date
      },
      {
        Header: "Left Footwear",
        accessor: "footwear_left",
        Cell: ({ value }) => (value ? value : "n/a"),
      },
      {
        Header: "Right Footwear",
        accessor: "footwear_right",
        Cell: ({ value }) => (value ? value : "n/a"),
      },
      {
        Header: "Assistive Device",
        accessor: "assistive_device",
        Cell: ({ value }) => (value ? value : "n/a"),
      },
    ],
    []
  );

  // Pass filtered data to react-table
  const data = useMemo(() => filteredData, [filteredData]);

  // Set up the table instance
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data }, useSortBy);

  return (
    <div className="card mb-4">
      <div className="d-flex card-header align-items-center justify-content-between">
        Collections
        {active_subject?.account_type === patient_account_types.remote ? (
          <div className="d-flex align-items-center">
            Days Collected: {fraction} {/* Display the fraction */}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="card-body">
        <div className="table-responsive" style={{ maxHeight: "275px", overflowY: "auto" }}>
          <table className="table table-bordered" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, headerGroupIdx) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIdx}>
                  {headerGroup.headers.map((column, columnIdx) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} key={columnIdx} style={{ cursor: column.canSort ? "pointer" : "default" }}>
                      {column.render("Header")}
                      <span style={{ marginLeft: "8px" }}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <img src={require("../../images/down_arrow.png")} alt="Descending" />
                          ) : (
                            <img src={require("../../images/up_arrow.png")} alt="Ascending" />
                          )
                        ) : column.canSort ? (
                          <img src={require("../../images/default.png")} alt="Unsorted" />
                        ) : null}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} style={{ cursor: "pointer" }} className="link-primary" onClick={() => onRowClick(row.original)}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={columns.length}>No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
