import IndividualNormalRange from './IndividualNormalRange';

export default class NormalRanges {
  constructor(
    {
      age_group = null,
      stride_time_mean = null,
      stride_time_std = null,
      stance_pct_mean = null,
      stance_pct_std = null,
      swing_pct_mean = null,
      swing_pct_std = null,
      stance_time_mean = null,
      stance_time_std = null,
      swing_time_mean = null,
      swing_time_std = null,
    } = {}
  ) {
    this.age_group = age_group;
    this.all_valid =
      stride_time_mean &&
      stride_time_std &&
      stance_pct_mean &&
      stance_pct_std &&
      swing_pct_mean &&
      swing_pct_std &&
      stance_time_mean &&
      stance_time_std &&
      swing_time_mean &&
      swing_time_std
        ? true
        : false;
    this.stride_time = new IndividualNormalRange({
      mean: stride_time_mean,
      std: stride_time_std,
    });
    this.stance_pct = new IndividualNormalRange({
      mean: stance_pct_mean,
      std: stance_pct_std,
    });
    this.swing_pct = new IndividualNormalRange({
      mean: swing_pct_mean,
      std: swing_pct_std,
    });
    this.stance_time = new IndividualNormalRange({
      mean: stance_time_mean,
      std: stance_time_std,
    });
    this.swing_time = new IndividualNormalRange({
      mean: swing_time_mean,
      std: swing_time_std,
    });
    this.stance_asi = new IndividualNormalRange({ mean: 1, std: 0.51 });
    this.swing_asi = new IndividualNormalRange({ mean: 1, std: 0.51 });
  }

  static createFromAPI(normal_range_api) {
    const norm = normal_range_api.norm;
    if (!norm) {
      return new NormalRanges();
    }

    return new NormalRanges({
      age_group: norm.age_group,
      stride_time_mean: norm.stride_time_mean,
      stride_time_std: norm.stride_time_std,
      stance_pct_mean: norm.stance_pct_mean,
      stance_pct_std: norm.stance_pct_std,
      swing_pct_mean: norm.swing_pct_mean,
      swing_pct_std: norm.swing_pct_std,
      stance_time_mean: norm.stance_time_mean,
      stance_time_std: norm.stance_time_std,
      swing_time_mean: norm.swing_time_mean,
      swing_time_std: norm.swing_time_std,
    });
  }

  get age_group() {
    return this._age_group;
  }
  set age_group(age_group) {
    this._age_group = age_group;
  }

  get stride_time() {
    return this._stride_time;
  }
  set stride_time(stride_time) {
    this._stride_time = stride_time;
  }

  get stance_pct() {
    return this._stance_pct;
  }
  set stance_pct(stance_pct) {
    this._stance_pct = stance_pct;
  }

  get swing_pct() {
    return this._swing_pct;
  }
  set swing_pct(swing_pct) {
    this._swing_pct = swing_pct;
  }

  get stance_time() {
    return this._stance_time;
  }
  set stance_time(stance_time) {
    this._stance_time = stance_time;
  }

  get swing_time() {
    return this._swing_time;
  }
  set swing_time(swing_time) {
    this._swing_time = swing_time;
  }

  get stance_asi() {
    return this._stance_asi;
  }
  set stance_asi(stance_asi) {
    this._stance_asi = stance_asi;
  }

  get swing_asi() {
    return this._swing_asi;
  }
  set swing_asi(swing_asi) {
    this._swing_asi = swing_asi;
  }
}
