import React, { useState, useRef, useEffect, useMemo } from "react";
import { Line } from "react-chartjs-2";
import { tooltipOptions, generalOptions, axesOptions, zoomOptions, layoutOptions } from "../../assets/chart-defaults";
import Chart, { ChartData, ChartOptions } from "chart.js/auto";
import classificationConfig from "../../config/performance_score_class_ranges.json";

export default function PerformanceScoreGraph({
  labels,
  data,
  trialNames,
  lastUpdatedTime,
  timeAxis,
  minDate,
  maxDate,
  paddedMinDateStr,
  paddedMaxDateStr,
  ...props
}) {
  // Filter out NaN values from data and corresponding labels
  const filteredData = useMemo(() => data.filter((value) => !isNaN(value)), [data]);
  const filteredLabels = useMemo(() => labels.filter((_, index) => !isNaN(data[index])), [labels, data]);
  const filteredTrialNames = useMemo(() => trialNames.filter((_, index) => !isNaN(data[index])), [trialNames, data]);

  const filteredDataByDate = useMemo(() => {
    return data.filter((value, index) => {
      // Convert label to a Date object
      const rawLabelDate = new Date(`${labels[index]}T00:00:00-05:00`);
      // Normalize label date to midnight
      const labelDate = new Date(rawLabelDate.getFullYear(), rawLabelDate.getMonth(), rawLabelDate.getDate());
      // Normalize minDate and maxDate to midnight
      const minDateNoTime = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate());
      const maxDateNoTime = new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate());

      return labelDate >= minDateNoTime && labelDate <= maxDateNoTime && !isNaN(value);
    });
  }, [data, labels, minDate, maxDate]);

  const formattedDelta = useMemo(() => {
    const delta = filteredData.length >= 2 ? filteredData[filteredData.length - 1] - filteredData[filteredData.length - 2] : null;
    return delta !== null && !Number.isNaN(delta) ? `${delta > 0 ? "+" : ""}${delta.toFixed(2)}` : "N/A";
  }, [filteredData]);

  const stanceASIRef = useRef<Chart<"line">>(null);
  const [averageText, setAverageText] = useState(0);

  useEffect(() => {
    if (filteredDataByDate.length > 0) {
      const average = filteredDataByDate.reduce((sum, value) => sum + value, 0) / filteredDataByDate.length;
      setAverageText(average.toFixed(2));
    } else {
      setAverageText(0);
    }
  }, [filteredDataByDate]);

  function hexToRgba(hex, opacity) {
    hex = hex.replace(/^#/, "");
    if (hex.length === 3) {
      hex = hex
        .split("")
        .map((c) => c + c)
        .join("");
    }
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgba(${r},${g},${b},${opacity})`;
  }

  const getZoneLabel = (value) => {
    for (let i = 0; i < classificationConfig.length; i++) {
      const zone = classificationConfig[i];
      if (i < classificationConfig.length - 1) {
        if (value >= zone.min && value < zone.max) {
          return zone.label;
        }
      } else {
        if (value >= zone.min && value <= zone.max) {
          return zone.label;
        }
      }
    }
    return "";
  };

  const zoneDatasets = useMemo(() => {
    return classificationConfig.flatMap((zone) => [
      {
        type: "line",
        label: `${zone.label} (${zone.min} - ${zone.max})`,
        data: [
          { x: paddedMinDateStr, y: zone.min },
          { x: paddedMaxDateStr, y: zone.min },
        ],
        borderWidth: 0,
        backgroundColor: hexToRgba(zone.color, 0.3),
        tooltip: { enabled: false },
        fill: "+1",
        pointRadius: 0,
        order: 0,
      },
      {
        type: "line",
        label: "hidden",
        data: [
          { x: paddedMinDateStr, y: zone.max },
          { x: paddedMaxDateStr, y: zone.max },
        ],
        borderWidth: 0,
        tooltip: { enabled: false },
        backgroundColor: zone.color,
        fill: false,
        pointRadius: 0,
        order: 0,
      },
    ]);
  }, [paddedMinDateStr, paddedMaxDateStr]);

  const mainLineDataset = useMemo(
    () => ({
      type: "line",
      label: "hidden",
      data: filteredData.map((val, idx) => ({
        x: filteredLabels[idx],
        y: val,
      })),
      lineTension: 0.0,
      backgroundColor: "rgba(0,97,242,0.1)",
      borderColor: "black",
      pointRadius: 3,
      pointBackgroundColor: "black",
      pointBorderColor: "black",
      pointHoverRadius: 3,
      pointHoverBackgroundColor: "black",
      pointHoverBorderColor: "black",
      pointHitRadius: 10,
      pointBorderWidth: 2,
      order: 1,
    }),
    [filteredLabels, filteredData]
  );

  const chartData = useMemo<ChartData<"line">>(
    () => ({
      labels: [],
      datasets: [...zoneDatasets, mainLineDataset],
    }),
    [zoneDatasets, mainLineDataset]
  );

  const chartOptions = useMemo<ChartOptions<"line">>(
    () => ({
      normalized: generalOptions.normalized,
      animation: generalOptions.animation,
      maintainAspectRatio: generalOptions.maintainAspectRatio,
      layout: layoutOptions.normalLayout,
      scales: {
        x: {
          type: "time",
          time: { unit: timeAxis },
          min: paddedMinDateStr,
          max: paddedMaxDateStr,
          grid: axesOptions.disabledGrid,
          ticks: {
            callback: function (value) {
              return value;
            },
            maxTicksLimit: axesOptions.maxTicksLimitX,
          },
        },
        y: {
          suggestedMin: 0,
          ticks: {
            maxTicksLimit: axesOptions.maxTicksLimitY,
            padding: 20,
            callback: function (value) {
              return value;
            },
          },
          grid: axesOptions.enabledGrid,
        },
      },
      plugins: {
        datalabels: { display: false },
        legend: {
          display: true,
          labels: {
            filter: function (item) {
              if (!item.text.includes("hidden")) {
                if (typeof item.fillStyle === "string" && item.fillStyle.startsWith("rgba")) {
                  item.fillStyle = item.fillStyle.replace(/, ?[\d.]+\)$/, ", 1)");
                }
                return true;
              }
              return false;
            },
          },
          onClick: () => {},
        },
        tooltip: {
          titleMarginBottom: tooltipOptions.titleMarginBottom,
          titleColor: tooltipOptions.titleColor,
          titleFont: tooltipOptions.titleFont,
          bodyFont: tooltipOptions.bodyFont,
          backgroundColor: tooltipOptions.backgroundColor,
          bodyColor: tooltipOptions.bodyColor,
          borderColor: tooltipOptions.borderColor,
          borderWidth: tooltipOptions.borderWidth,
          displayColors: tooltipOptions.displayColors,
          caretPadding: tooltipOptions.caretPadding,
          padding: tooltipOptions.padding,
          callbacks: {
            title: function (tooltipItems) {
              let trialName = filteredTrialNames.length > 0 ? filteredTrialNames[tooltipItems[0].dataIndex] : "Unknown";
              return trialName;
            },
            label: function (context) {
              let splitIndex = context.label.lastIndexOf(",");
              let labelValue = context.label;
              if (timeAxis === "hour") {
                labelValue = context.label;
              } else if (timeAxis === "day") {
                labelValue = context.label.slice(0, splitIndex);
              } else if (timeAxis === "month") {
                labelValue = context.label.slice(0, 4);
              } else if (timeAxis === "millisecond") {
                labelValue = context.label;
              }
              return `${labelValue}: ${context.parsed.y}`;
            },
          },
        },
        zoom: {
          pan: {
            enabled: zoomOptions.panEnabled,
            mode: "x",
          },
          limits: {
            y: { min: 0, max: 600 },
          },
          zoom: {
            wheel: { enabled: zoomOptions.zoomEnabled },
            pinch: { enabled: zoomOptions.zoomEnabled },
            mode: "x",
          },
        },
      },
    }),
    [timeAxis, filteredTrialNames, paddedMinDateStr, paddedMaxDateStr]
  );

  return (
    <div className="card mb-4">
      <div className="stanceASIGraph">
        <div className="d-flex card-header align-items-center justify-content-between">
          Performance Score
          <div className="d-flex align-items-center">
            {formattedDelta !== "N/A" && (
              <span style={{ fontSize: "0.9rem" }} className="mr-3">
                Since Last Collection: {formattedDelta}
              </span>
            )}
            {(zoomOptions.panEnabled || zoomOptions.zoomEnabled) && (
              <button
                className="btn btn-transparent-dark rounded-pill"
                type="button"
                onClick={() => {
                  if (stanceASIRef.current) {
                    stanceASIRef.current.resetZoom();
                  }
                }}
              >
                Reset
              </button>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="chart-pie" style={{ position: "relative" }}>
            <Line ref={stanceASIRef} data={chartData} height={50} width={100} options={chartOptions} />
          </div>
        </div>
        <div className="card-footer small d-flex justify-content-between">
          <span>
            Current Performance Score:{" "}
            {filteredData.length > 0 ? `${filteredData[filteredData.length - 1]} - ${getZoneLabel(filteredData[filteredData.length - 1])}` : "N/A"}
          </span>
          <span>
            Average ({minDate.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "2-digit" })} -{" "}
            {maxDate.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "2-digit" })}
            ): {averageText} - {getZoneLabel(averageText)}
          </span>
        </div>
      </div>
    </div>
  );
}
