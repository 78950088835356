import moment from 'moment';

export default class PerformanceScore {
  constructor({
    performance_score_id = null,
    date,
    raw_score,
    rolling_avg_score = null,
    trial_id,
  }) {
    this.performance_score_id = performance_score_id;
    this.date = date;
    this.raw_score = raw_score;
    this.rolling_avg_score = rolling_avg_score;
    this.trial_id = trial_id;
  }

  static createFromAPI(performance_score_api) {
    let localDate = performance_score_api.date
      ? moment
          .utc(performance_score_api.date)
          .local()
          .format('YYYY-MM-DD HH:mm:ss')
      : performance_score_api.date;
    return new PerformanceScore({
      performance_score_id: performance_score_api.idperformance_score,
      date: localDate,
      raw_score: performance_score_api.raw_score ? performance_score_api.raw_score : NaN,
      trial_id: performance_score_api.trial_id,
    });
  }

  get performance_score_id() {
    return this._performance_score_id;
  }
  set performance_score_id(performance_score_id) {
    this._performance_score_id = performance_score_id;
  }

  get date() {
    return this._date;
  }
  set date(date) {
    this._date = date;
  }

  get raw_score() {
    return this._raw_score;
  }
  set raw_score(raw_score) {
    this._raw_score = raw_score;
  }

  get trial_id() {
    return this._trial_id;
  }
  set trial_id(trial_id) {
    this._trial_id = trial_id;
  }
}
