import React, { useState, useRef, useEffect, useMemo } from "react";
import { Line } from "react-chartjs-2";
import { tooltipOptions, generalOptions, axesOptions, zoomOptions, layoutOptions } from "../../assets/chart-defaults";
import Chart, { ChartData, ChartOptions } from "chart.js/auto";

export default function SwingASIGraph({ labels, data, trialNames, lastUpdatedTime, timeAxis, minDate, maxDate, paddedMinDateStr, paddedMaxDateStr, norm, ...props }) {
  // Filter out NaN values from data and corresponding labels
  const filteredData = useMemo(() => data.filter((value) => !isNaN(value)), [data]);
  const filteredLabels = useMemo(() => labels.filter((_, index) => !isNaN(data[index])), [labels, data]);
  const filteredTrialNames = useMemo(() => trialNames.filter((_, index) => !isNaN(data[index])), [trialNames, data]);
  const filteredDataByDate = useMemo(() => {
    return data.filter((value, index) => {
      // Convert label to a Date object
      const rawLabelDate = new Date(`${labels[index]}T00:00:00-05:00`); // Convert label to Date object
      // Normalize label date to midnight
      const labelDate = new Date(rawLabelDate.getFullYear(), rawLabelDate.getMonth(), rawLabelDate.getDate());

      // Normalize minDate and maxDate to midnight if not already
      const minDateNoTime = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate());
      const maxDateNoTime = new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate());

      return (
        labelDate >= minDateNoTime &&
        labelDate <= maxDateNoTime &&
        !isNaN(value)
      );
    });
  }, [data, labels, minDate, maxDate]);

  const formattedDelta = useMemo(() => {
    const delta = filteredData.length >= 2 ? filteredData[filteredData.length - 1] - filteredData[filteredData.length - 2] : null;
    return delta !== null && !Number.isNaN(delta) ? `${delta > 0 ? "+" : ""}${delta.toFixed(2)}%` : "N/A";
  }, [filteredData]);

  const swingASIRef = useRef<Chart<"line">>(null);
  const [averageText, setAverageText] = useState(0);

  useEffect(() => {
    if (filteredDataByDate.length > 0) {
      const average = filteredDataByDate.reduce((sum, value) => sum + value, 0) / filteredDataByDate.length;
      setAverageText(average.toFixed(2));
    } else {
      setAverageText(0); // Default to 0 if no data
    }
  }, [filteredDataByDate]); // Run effect when filteredDataByDate changes

  const lowerBound = norm && norm.swing_asi.valid ? norm.swing_asi.range.low : 0;
  const upperBound = norm && norm.swing_asi.valid  ? norm.swing_asi.range.high : 0;

  const normRangeDataset = useMemo(
    () => ({
      type: "line",
      label: "Normal Range", // Shown in legend
      data: [
        { x: paddedMinDateStr, y: lowerBound },
        { x: paddedMaxDateStr, y: lowerBound },
      ],
      borderColor: "rgba(173, 216, 230, 0)",
      backgroundColor: "rgba(173, 216, 230, 0.3)",
      fill: "+1",
      pointRadius: 0,
      pointHoverRadius: 0, // No hover effect
      order: 0,
    }),
    [paddedMinDateStr, paddedMaxDateStr, lowerBound]
  );

  const normRangeUpperDataset = useMemo(
    () => ({
      type: "line",
      label: "hidden", // Hide from legend
      data: [
        { x: paddedMinDateStr, y: upperBound },
        { x: paddedMaxDateStr, y: upperBound },
      ],
      borderColor: "rgba(173, 216, 230, 0)",
      backgroundColor: "rgba(173, 216, 230, 0.3)",
      fill: false, // End fill from previous
      pointRadius: 0,
      pointHoverRadius: 0, // No hover effect
      order: 0,
    }),
    [paddedMinDateStr, paddedMaxDateStr, upperBound]
  );

  const mainLineDataset = useMemo(
    () => ({
      type: "line",
      label: "hidden",
      data: filteredData.map((val, idx) => ({
        x: filteredLabels[idx],
        y: val,
      })),
      // labels: filteredLabels.length > 0 ? filteredLabels : [],
      lineTension: 0.0,
      backgroundColor: "rgba(0,97,242,0.1)",
      borderColor: "rgba(0, 97, 242, 1)",
      pointRadius: 3,
      // fill: true,
      pointBackgroundColor: "rgba(0, 97, 242, 1)",
      pointBorderColor: "rgba(0, 97, 242, 1)",
      pointHoverRadius: 3,
      pointHoverBackgroundColor: "rgba(0, 97, 242, 1)",
      pointHoverBorderColor: "rgba(0, 97, 242, 1)",
      pointHitRadius: 10,
      pointBorderWidth: 2,
      order: 1,
    }),
    [filteredLabels, filteredData]
  );

  // Combine the three datasets
  const placeholder_swingASIData = useMemo<ChartData<"line">>(
    () => ({
      labels: [], // We'll rely on object-based data for x,y
      datasets: [normRangeDataset, normRangeUpperDataset, mainLineDataset],
    }),
    [normRangeDataset, normRangeUpperDataset, mainLineDataset]
  );

  const placeholder_swingASIOptions = useMemo<ChartOptions<"line">>(
    () => ({
      normalized: generalOptions.normalized,
      animation: generalOptions.animation,
      maintainAspectRatio: generalOptions.maintainAspectRatio,
      layout: layoutOptions.normalLayout,
      scales: {
        x: {
          type: "time",
          time: {
            unit: timeAxis,
          },
          min: paddedMinDateStr, // Set min date
          max: paddedMaxDateStr, // Set max date
          grid: axesOptions.disabledGrid,
          ticks: {
            callback: function (value, index, values) {
              let toReturn = value;
              // if (timeAxis === "millisecond") {
              //   let splitVal = value.split(" ");
              //   let index = splitVal[0].lastIndexOf(".");
              //   toReturn = splitVal[0].substring(0, index) + " " + splitVal[1];
              // }
              return toReturn;
            },
            maxTicksLimit: axesOptions.maxTicksLimitX,
          },
        },
        y: {
          suggestedMin: 0,
          // max: 100,
          ticks: {
            maxTicksLimit: axesOptions.maxTicksLimitY,
            padding: 20,
            callback: function (value, index, values) {
              return value + "%";
            },
          },
          grid: axesOptions.enabledGrid,
        },
      },
      plugins: {
        datalabels: {
          display: false,
        },
        legend: {
          display: true,
          labels: {
            // Filter out items labeled "hidden" from the legend
            filter: function (item) {
              return !item.text.includes("hidden");
            },
          },
        },
        tooltip: {
          filter: function (tooltipItem) {
            // datasetIndex 2 is main line. 0 is Norm Range, 1 is hidden upper.
            return tooltipItem.datasetIndex === 2;
          },
          titleMarginBottom: tooltipOptions.titleMarginBottom,
          titleColor: tooltipOptions.titleColor,
          titleFont: tooltipOptions.titleFont,
          bodyFont: tooltipOptions.bodyFont,
          backgroundColor: tooltipOptions.backgroundColor,
          bodyColor: tooltipOptions.bodyColor,
          borderColor: tooltipOptions.borderColor,
          borderWidth: tooltipOptions.borderWidth,
          displayColors: tooltipOptions.displayColors,
          caretPadding: tooltipOptions.caretPadding,
          padding: tooltipOptions.padding,
          callbacks: {
            title: function (tooltipItems) {
              let trialName = filteredTrialNames.length > 0 ? filteredTrialNames[tooltipItems[0].dataIndex] : null;
              if (!trialName) {
                trialName = "Unknown"; // Fallback if name is not available
              }
              return trialName;
            },
            label: function (context) {
              let splitIndex = context.label.lastIndexOf(",");
              let labelValue = context.label;

              if (timeAxis === "hour") {
                labelValue = context.label;
              } else if (timeAxis === "day") {
                labelValue = context.label.slice(0, splitIndex);
              } else if (timeAxis === "month") {
                labelValue = context.label.slice(0, 4);
              } else if (timeAxis === "millisecond") {
                labelValue = context.label;
              }
              return `${labelValue}: ${context.parsed.y}%`;
            },
          },
        },
        zoom: {
          pan: {
            enabled: zoomOptions.panEnabled,
            mode: "x",
          },
          limits: {
            // x: { min: "2021-11-05T23:39:30", max: "2021-11-08T11:39:30" },
            y: { min: 0, max: 600 },
          },
          zoom: {
            wheel: {
              enabled: zoomOptions.zoomEnabled,
            },
            pinch: {
              enabled: zoomOptions.zoomEnabled,
            },
            mode: "x",
          },
        },
      },
    }),
    [timeAxis, filteredTrialNames, paddedMinDateStr, paddedMaxDateStr]
  );

  useEffect(() => {
    setSwingASIData(placeholder_swingASIData);
    setSwingASIOptions(placeholder_swingASIOptions);
  }, [placeholder_swingASIData, placeholder_swingASIOptions]);

  const [swingASIData, setSwingASIData] = useState<ChartData<"line">>(placeholder_swingASIData);
  const [swingASIOptions, setSwingASIOptions] = useState<ChartOptions<"line">>(placeholder_swingASIOptions);

  return (
    <div className="card mb-4">
      <div className="swingASIGraph">
        <div className="d-flex card-header align-items-center justify-content-between">
          Swing Asymmetry
          <div className="d-flex align-items-center">
            {formattedDelta !== "N/A" && (
              <span style={{ fontSize: "0.9rem" }} className="mr-3">
                Since Last Collection: {formattedDelta}
              </span>
            )}
            {zoomOptions.panEnabled || zoomOptions.zoomEnabled ? (
              <button
                className="btn btn-transparent-dark rounded-pill"
                type="button"
                onClick={() => {
                  if (swingASIRef.current) {
                    swingASIRef.current.resetZoom();
                  }
                }}
              >
                Reset
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="chart-pie" style={{ position: "relative" }}>
            <Line ref={swingASIRef} data={swingASIData} height={50} width={100} options={swingASIOptions}></Line>
          </div>
        </div>
        <div className="card-footer small d-flex justify-content-between">
          <span>Normal Range: {norm && norm.swing_asi.valid ? `${lowerBound} - ${upperBound}%` : "N/A"}</span>
          <span>
            Average ({minDate.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "2-digit" })} -{" "}
            {maxDate.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "2-digit" })}): {averageText}%
          </span>
        </div>
      </div>
    </div>
  );
}
