import React, { useState, useRef, useEffect, useMemo } from "react";
import { Line } from "react-chartjs-2";
import { tooltipOptions, generalOptions, axesOptions, zoomOptions, layoutOptions } from "../../assets/chart-defaults";
import Chart, { ChartData, ChartOptions } from "chart.js/auto";
import "chartjs-plugin-zoom"; // If you use zoom/pan

export default function StrideTimeGraph({
  labels,
  data,
  trialNames,
  lastUpdatedTime,
  timeAxis,
  minDate,
  maxDate,
  paddedMinDateStr,
  paddedMaxDateStr,
  norm,
  ...props
}) {
  // Filter out invalid data
  const filteredData = useMemo(() => data.filter((value) => !isNaN(value)), [data]);
  const filteredLabels = useMemo(() => labels.filter((_, index) => !isNaN(data[index])), [labels, data]);
  const filteredTrialNames = useMemo(() => trialNames.filter((_, index) => !isNaN(data[index])), [trialNames, data]);

  // Compute the difference between the last two points
  const formattedDelta = useMemo(() => {
    if (filteredData.length < 2) return "N/A";
    const lastVal = filteredData[filteredData.length - 1];
    const prevVal = filteredData[filteredData.length - 2];
    const delta = lastVal - prevVal;
    if (Number.isNaN(delta)) return "N/A";
    return `${delta > 0 ? "+" : ""}${delta.toFixed(2)} ms`;
  }, [filteredData]);

  // Compute average within the min/max date range
  const filteredDataByDate = useMemo(() => {
    return data.filter((value, index) => {
      // Convert label to a Date object
      const rawLabelDate = new Date(`${labels[index]}T00:00:00-05:00`); // Convert label to Date object
      // Normalize label date to midnight
      const labelDate = new Date(rawLabelDate.getFullYear(), rawLabelDate.getMonth(), rawLabelDate.getDate());

      // Normalize minDate and maxDate to midnight if not already
      const minDateNoTime = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate());
      const maxDateNoTime = new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate());

      return (
        labelDate >= minDateNoTime &&
        labelDate <= maxDateNoTime &&
        !isNaN(value)
      );
    });
  }, [data, labels, minDate, maxDate]);

  const [averageText, setAverageText] = useState("0");
  useEffect(() => {
    if (filteredDataByDate.length === 0) {
      setAverageText("0");
    } else {
      const avg = filteredDataByDate.reduce((sum, value) => sum + value, 0) / filteredDataByDate.length;
      setAverageText(avg.toFixed(2));
    }
  }, [filteredDataByDate]);

  // Norm bounds
  const lowerBound = norm && norm.stride_time.valid ? norm.stride_time.range.low : 0;
  const upperBound = norm && norm.stride_time.valid ? norm.stride_time.range.high : 0

  // ----- Norm Range Datasets -----
  // We define two datasets with just two points each
  // spanning from paddedMinDateStr to paddedMaxDateStr.
  // "Norm Range" -> the one that appears in the legend (fill: +1)
  // "hidden" -> the upper boundary (no legend) to complete the fill.
  const normRangeDataset = useMemo(
    () => ({
      type: "line",
      label: norm ? "Normal Range" : "hidden", // Shown in legend
      data: [
        { x: paddedMinDateStr, y: lowerBound },
        { x: paddedMaxDateStr, y: lowerBound },
      ],
      borderColor: "rgba(173, 216, 230, 0)",
      backgroundColor: "rgba(173, 216, 230, 0.3)",
      fill: "+1",
      pointRadius: 0,
      pointHoverRadius: 0, // No hover effect
      order: 0,
    }),
    [paddedMinDateStr, paddedMaxDateStr, lowerBound, norm]
  );

  const normRangeUpperDataset = useMemo(
    () => ({
      type: "line",
      label: "hidden", // Hide from legend
      data: [
        { x: paddedMinDateStr, y: upperBound },
        { x: paddedMaxDateStr, y: upperBound },
      ],
      borderColor: "rgba(173, 216, 230, 0)",
      backgroundColor: "rgba(173, 216, 230, 0.3)",
      fill: false, // End fill from previous
      pointRadius: 0,
      pointHoverRadius: 0, // No hover effect
      order: 0,
    }),
    [paddedMinDateStr, paddedMaxDateStr, upperBound]
  );

  // ----- Main Line Dataset -----
  // This dataset will show the line points/hover as normal.
  // We'll give it a unique label, e.g. "Stride Time," if you want it
  // in the legend. If not, label it "hidden" and filter from legend.
  const mainLineDataset = useMemo(
    () => ({
      type: "line",
      label: "hidden", // or "hidden" if you don't want it in the legend
      data: filteredData.map((val, idx) => ({
        x: filteredLabels[idx],
        y: val,
      })),
      lineTension: 0,
      borderColor: "rgba(0, 97, 242, 1)",
      backgroundColor: "rgba(0, 97, 242, 0.1)",
      pointRadius: 3,
      pointBackgroundColor: "rgba(0, 97, 242, 1)",
      pointBorderColor: "rgba(0, 97, 242, 1)",
      pointHoverRadius: 4,
      pointHoverBackgroundColor: "rgba(0, 97, 242, 1)",
      pointHoverBorderColor: "rgba(0, 97, 242, 1)",
      pointHitRadius: 10,
      pointBorderWidth: 2,
      order: 1,
    }),
    [filteredData, filteredLabels]
  );

  // Combine the three datasets
  const placeholder_strideData = useMemo<ChartData<"line">>(
    () => ({
      labels: [], // We'll rely on object-based data for x,y
      datasets: [normRangeDataset, normRangeUpperDataset, mainLineDataset],
    }),
    [normRangeDataset, normRangeUpperDataset, mainLineDataset]
  );

  // ----- Chart Options -----
  const placeholder_strideOptions = useMemo<ChartOptions<"line">>(
    () => ({
      plugins: {
        datalabels: {
          display: false, // No point labels
        },
        legend: {
          display: true,
          labels: {
            // Filter out items labeled "hidden" from the legend
            filter: function (item) {
              return !item.text.includes("hidden");
            },
          },
        },
        tooltip: {
          filter: function (tooltipItem) {
            // datasetIndex 2 is main line. 0 is Norm Range, 1 is hidden upper.
            return tooltipItem.datasetIndex === 2;
          },
          titleMarginBottom: tooltipOptions.titleMarginBottom,
          titleColor: tooltipOptions.titleColor,
          titleFont: tooltipOptions.titleFont,
          bodyFont: tooltipOptions.bodyFont,
          backgroundColor: tooltipOptions.backgroundColor,
          bodyColor: tooltipOptions.bodyColor,
          borderColor: tooltipOptions.borderColor,
          borderWidth: tooltipOptions.borderWidth,
          displayColors: tooltipOptions.displayColors,
          caretPadding: tooltipOptions.caretPadding,
          padding: tooltipOptions.padding,
          callbacks: {
            title: function (tooltipItems) {
              if (!tooltipItems.length) return "";
              const dataIndex = tooltipItems[0].dataIndex;
              let trialName = filteredTrialNames.length > 0 ? filteredTrialNames[dataIndex] : null;
              if (!trialName) trialName = "Unknown";
              return trialName;
            },
            label: function (context) {
              // This is only called for datasetIndex=2 now
              let labelValue = context.label;
              const splitIndex = labelValue.lastIndexOf(",");
              if (timeAxis === "hour") {
                labelValue = context.label;
              } else if (timeAxis === "day") {
                labelValue = context.label.slice(0, splitIndex);
              } else if (timeAxis === "month") {
                labelValue = context.label.slice(0, 4);
              } else if (timeAxis === "millisecond") {
                labelValue = context.label;
              }
              return `${labelValue}: ${context.parsed.y} ms`;
            },
          },
        },
      },
      normalized: generalOptions.normalized,
      animation: generalOptions.animation,
      maintainAspectRatio: generalOptions.maintainAspectRatio,
      layout: layoutOptions.normalLayout,
      scales: {
        x: {
          type: "time",
          time: {
            unit: timeAxis,
          },
          min: paddedMinDateStr,
          max: paddedMaxDateStr,
          grid: axesOptions.disabledGrid,
          ticks: {
            maxTicksLimit: axesOptions.maxTicksLimitX,
          },
        },
        y: {
          suggestedMin: 0,
          ticks: {
            maxTicksLimit: axesOptions.maxTicksLimitY,
            padding: 20,
            callback: (value) => `${value} ms`,
          },
          grid: axesOptions.enabledGrid,
        },
      },
      zoom: {
        pan: {
          enabled: zoomOptions.panEnabled,
          mode: "x",
        },
        limits: {
          y: { min: 0, max: 600 },
        },
        zoom: {
          wheel: {
            enabled: zoomOptions.zoomEnabled,
          },
          pinch: {
            enabled: zoomOptions.zoomEnabled,
          },
          mode: "x",
        },
      },
    }),
    [filteredTrialNames, timeAxis, paddedMinDateStr, paddedMaxDateStr]
  );

  // Maintain chart state
  const [strideDataState, setStrideDataState] = useState<ChartData<"line">>(placeholder_strideData);
  const [strideOptionsState, setStrideOptionsState] = useState<ChartOptions<"line">>(placeholder_strideOptions);

  const strideRef = useRef(null);

  useEffect(() => {
    setStrideDataState(placeholder_strideData);
    setStrideOptionsState(placeholder_strideOptions);
  }, [placeholder_strideData, placeholder_strideOptions]);

  return (
    <div className="card mb-4">
      <div className="strideGraph">
        <div className="d-flex card-header align-items-center justify-content-between">
          Stride Times
          <div className="d-flex align-items-center">
            {formattedDelta !== "N/A" && (
              <span style={{ fontSize: "0.9rem" }} className="mr-3">
                Since Last Collection: {formattedDelta}
              </span>
            )}
            {zoomOptions.panEnabled || zoomOptions.zoomEnabled ? (
              <button
                className="btn btn-transparent-dark rounded-pill"
                type="button"
                onClick={() => {
                  if (strideRef.current) {
                    // @ts-ignore - resetZoom from 'chartjs-plugin-zoom'
                    strideRef.current.resetZoom();
                  }
                }}
              >
                Reset
              </button>
            ) : null}
          </div>
        </div>
        <div className="card-body">
          <div className="chart-pie" style={{ position: "relative" }}>
            <Line ref={strideRef} data={strideDataState} height={50} width={100} options={strideOptionsState} />
          </div>
        </div>
        <div className="card-footer small d-flex justify-content-between">
          <span>
            Normal Range: {norm && norm.stride_time.valid
              ? `${lowerBound.toFixed(2)} - ${upperBound.toFixed(2)} ms`
              : "N/A"}
          </span>
          <span>
            Average ({minDate.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "2-digit" })} -{" "}
            {maxDate.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "2-digit" })}): {averageText} ms
          </span>
        </div>
      </div>
    </div>
  );
}
